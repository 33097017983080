import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(_ctx.buttonType)
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_fa_icon, {
          key: 0,
          class: "fa-spin",
          icon: ['far', 'spinner-third']
        }))
      : (_ctx.showArrow)
        ? (_openBlock(), _createBlock(_component_fa_icon, {
            key: 1,
            icon: ['far', _ctx.icon]
          }, null, 8, ["icon"]))
        : _createCommentVNode("", true)
  ], 2))
}