import { Guard } from './guard';
import { IMemberPermissions } from '../../entities/memberPermissions';
import { useOrgStore } from '@/store/orgStore';

export default function isTrue(predicate: (permissions: IMemberPermissions) => boolean): Guard {
  return (to, from, next) => {
    const store = useOrgStore();
    if (store.permissions && predicate(store.permissions)) {
      next();
    } else {
      next({ name: 'Downloads', params: to.params });
    }
  };
}
