import axios from 'axios';
import { IMember } from '../../entities/member';
import { IMemberPermissions } from '../../entities/memberPermissions';
import { IOrganization } from '../../entities/organization';
import { IOutgoingInvite } from '../../entities/outgoingInvite';
import { IPaginationResponse } from './paginationResponse';

export interface ICreateOrganizationData {
  name: string;
  slug: string;
}

export async function createOrg(data: ICreateOrganizationData): Promise<IOrganization> {
  const { data: org } = await axios.post<IOrganization>('/orgs', data);
  return org;
}

export async function getOrgs(): Promise<IOrganization[]> {
  const { data: orgs } = await axios.get<IOrganization[]>('/orgs');
  return orgs;
}

export async function getOrgBySubdomain(subdomain: string): Promise<IOrganization | undefined> {
  const { data: orgs } = await axios.get<IOrganization[]>(`/orgs?slug=${subdomain}`);
  if (orgs.length === 0) {
    return undefined;
  }

  return orgs[0];
}

export interface ICreateInviteData {
  email: string;
  permissions: IMemberPermissions;
}

export async function createInvite(
  orgID: number, data: ICreateInviteData,
): Promise<string | undefined> {
  try {
    await axios.post<ICreateInviteData>(`/orgs/${orgID}/invites`, data);
    return undefined;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.response?.status === 409) {
        return 'Email address is already in use.';
      }

      if (err.response?.status === 403) {
        return 'You cannot invite members with administrator permissions.';
      }
    }

    throw err;
  }
}

export async function getInvites(orgID: number): Promise<IOutgoingInvite[]> {
  const { data: invites } = await axios.get<IOutgoingInvite[]>(`/orgs/${orgID}/invites`);
  return invites;
}

export async function revokeInvite(orgID: number, inviteID: number): Promise<void> {
  try {
    await axios.delete(`/orgs/${orgID}/invites/${inviteID}`);
  } catch (err) {
    if (!axios.isAxiosError(err) || err.response?.status !== 404) {
      throw err;
    }
  }
}

export async function getMembers(orgID: number): Promise<IPaginationResponse<Partial<IMember>>> {
  const response = await axios.get<Partial<IMember>[]>(`/orgs/${orgID}/members`);
  return {
    data: response.data,
    totalPages: parseInt(response.headers['x-total-pages'], 10),
  };
}

export async function getMember(orgID: number, memberID: string): Promise<IMember | undefined> {
  try {
    const { data: member } = await axios.get<IMember>(`/orgs/${orgID}/members/${memberID}`);
    return member;
  } catch (err) {
    if (axios.isAxiosError(err) && err.response?.status === 404) {
      return undefined;
    }

    throw err;
  }
}

export async function getPermissions(orgID: number): Promise<IMemberPermissions> {
  const { data: permissions } = await axios.get<IMemberPermissions>(
    `/orgs/${orgID}/members/@me/permissions`,
  );

  return permissions;
}

export async function updateMemberPermissions(
  orgID: number,
  memberID: number,
  permissions: IMemberPermissions,
): Promise<void> {
  await axios.put(`/orgs/${orgID}/members/${memberID}/permissions`, permissions);
}

export async function kickMember(orgID: number, memberID: number): Promise<void> {
  await axios.delete(`/orgs/${orgID}/members/${memberID}`);
}
