
import { defineComponent } from 'vue';
import PricingCard from './PricingCard.vue';

export default defineComponent({
  components: {
    PricingCard,
  },
  data() {
    return {
      regularFeatures: [
        'Discord and Steam rewards',
        'Steamcord Discord bot',
        'Unlimited game servers',
        'API access',
        '99.9% SLA',
      ],
      proFeatures: [
        'All Regular plan features',
        'Reddit and Twitch linking',
        'Custom themes',
        'Custom domains',
        'Organization members',
      ],
    };
  },
});
