
import axios from 'axios';
import { Options, Vue } from 'vue-class-component';
import { IContactErrors, validateContactData } from '@/services/validation/contact';
import Modal from '@/components/Modal.vue';

@Options({
  components: {
    Modal,
  },
})
export default class Contact extends Vue {
  contactData = {
    email: '',
    message: '',
  }

  loading = false;

  contactErrors: IContactErrors = {
    emailError: '',
    messageError: '',
    hasErrors: false,
  }

  formError = '';

  showSuccess = false;

  async onSubmit(): Promise<void> {
    if (this.loading) {
      return;
    }

    this.contactErrors = validateContactData(this.contactData);
    if (this.contactErrors.hasErrors) {
      return;
    }

    this.loading = true;

    try {
      this.formError = '';
      await axios.post('https://formspree.io/f/xyylrynj', this.contactData);
      this.contactData.email = '';
      this.contactData.message = '';
      this.showSuccess = true;
    } catch (err) {
      this.formError = 'Please try again later.';
      return;
    }

    this.loading = false;
  }
}
