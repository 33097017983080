import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowToLeft,
  faArrowToRight,
  faBars,
  // faBolt,
  faBook,
  faBoxOpen,
  faCheck,
  faChevronDown,
  faClock,
  faCode,
  faCodeMerge,
  // faCog,
  faCopy,
  // faCreditCard,
  // faDatabase,
  // faDownload,
  faEllipsisH,
  faExchange,
  faExclamationTriangle,
  faExternalLink,
  faEye,
  // faKey,
  faLifeRing,
  faPlus,
  faSearch,
  // faServer,
  faSpinnerThird,
  faTag,
  faTimes,
  faUnlink,
  faUserAlien,
  // faUserAstronaut,
  faUserTimes,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faBolt, faCog, faCreditCard, faDatabase, faDownload, faGlobeAmericas, faKey, faServer,
  faSignOutAlt, faUserAstronaut,
} from '@fortawesome/pro-solid-svg-icons';

import { faSortDown, faSortUp } from '@fortawesome/pro-duotone-svg-icons';

import {
  faDiscord,
  faCss3Alt,
  faGithub,
  faSteam,
} from '@fortawesome/free-brands-svg-icons';

import { faLock } from '@fortawesome/free-solid-svg-icons';

import axios from 'axios';
import FloatingVue from 'floating-vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { createPinia } from 'pinia';
import { createApp, markRaw } from 'vue';
import App from './App.vue';
import router from './router';
import { useAuthStore } from './store/authStore';
import BaseInput from '@/components/BaseInput.vue';
import BaseSwitch from '@/components/BaseSwitch.vue';
import BaseTextArea from '@/components/BaseTextArea.vue';
import BotAnchor from '@/components/BotAnchor.vue';
import ExternalAnchor from '@/components/ExternalAnchor.vue';
import InputTextGroup from '@/components/InputTextGroup.vue';
import PlusButton from '@/components/buttons/PlusButton.vue';
import LoadingButton from '@/components/buttons/LoadingButton.vue';

axios.defaults.baseURL = process.env.VUE_APP_BASE_URI;
axios.interceptors.response.use((res) => res, (err) => {
  if (err.response?.status === 401) {
    router.push({ name: 'Sign In Form' });
  }

  // if (err.response?.status === 403) {
  //   router.push({ name: 'Sign Out' });
  // }

  if (err.response?.status === 500) {
    router.push({ name: 'Internal Server Error' });
  }

  return Promise.reject(err);
});

library.add(faArrowDown, faArrowLeft, faArrowRight, faArrowToLeft, faArrowToRight, faBars, faBolt,
  faBook, faBoxOpen, faCheck, faChevronDown, faClock, faCode, faCodeMerge, faCog, faCopy,
  faCreditCard, faCss3Alt, faDatabase, faDownload, faEllipsisH, faExchange, faExclamationTriangle,
  faExternalLink, faEye, faGlobeAmericas, faKey, faLifeRing, faLock, faPlus, faSearch, faServer,
  faSortDown, faSignOutAlt, faSortUp, faSpinnerThird, faDiscord, faGithub, faSteam, faTag, faTimes,
  faUnlink, faUserAlien, faUserAstronaut, faUserTimes);

const pinia = createPinia().use((p) => {
  // eslint-disable-next-line no-param-reassign
  p.store.router = markRaw(router);
});

(async () => {
  const app = createApp(App)
    .component('base-input', BaseInput)
    .component('base-switch', BaseSwitch)
    .component('base-text-area', BaseTextArea)
    .component('bot-anchor', BotAnchor)
    .component('external-anchor', ExternalAnchor)
    .component('input-text-group', InputTextGroup)
    .component('loading-button', LoadingButton)
    .component('plus-button', PlusButton)
    .component('fa-icon', FontAwesomeIcon)
    .use(FloatingVue, {
      themes: {
        'dropdown-button': {
          placement: 'bottom',
          $extend: 'dropdown',
          $resetCss: true,
        },
      },
    })
    .use(pinia);

  const authStore = useAuthStore();
  if (authStore.isSignedIn) {
    authStore.setEmailVerified();
  }

  app.use(router).mount('#app');

  const { VUE_APP_RELEASE } = process.env;

  const storedRelease = localStorage.getItem('release')?.toString();
  if (storedRelease !== VUE_APP_RELEASE.toString()) {
    localStorage.setItem('release', VUE_APP_RELEASE.toString());
    authStore.signOut();
  }
})();
