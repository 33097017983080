import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home no-margin-top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_hero = _resolveComponent("hero")!
  const _component_introduction = _resolveComponent("introduction")!
  const _component_demo = _resolveComponent("demo")!
  const _component_features = _resolveComponent("features")!
  const _component_contact = _resolveComponent("contact")!
  const _component_pricing = _resolveComponent("pricing")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createVNode(_component_hero),
    _createVNode(_component_introduction),
    _createVNode(_component_demo),
    _createVNode(_component_features),
    _createVNode(_component_contact),
    _createVNode(_component_pricing)
  ]))
}