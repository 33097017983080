import { string, ValidationError } from 'yup';
import RequiredStringSchema from 'yup/lib/string';

const emailFormatMessage = 'Email format invalid.';
const requiredMessage = 'This is a required field.';

export const requiredStringSchema = string().required(requiredMessage);
export const emailSchema = requiredStringSchema.email(emailFormatMessage);

export function validate(schema: RequiredStringSchema<string | null | undefined>, value: string):
  string {
  try {
    schema.validateSync(value);
    return '';
  } catch (err) {
    return (err as ValidationError).message;
  }
}

export function validateEmail(email: string): string {
  return validate(emailSchema, email);
}

export function validateRequired(value: string): string {
  return validate(requiredStringSchema, value);
}
