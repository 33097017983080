import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pricing-cards" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pricing_card = _resolveComponent("pricing-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_pricing_card, {
      name: "Regular",
      price: "$8.99",
      features: _ctx.regularFeatures
    }, null, 8, ["features"]),
    _createVNode(_component_pricing_card, {
      name: "Pro",
      price: "$12.99",
      features: _ctx.proFeatures,
      disabled: "",
      gradientBorder: ""
    }, null, 8, ["features"])
  ]))
}