<template>
  <div class="modal__container fixed-container" @click.self="$emit('close')">
    <div class="modal">
      <fa-icon class="modal__close" :icon="['far', 'times']" @click="$emit('close')" />
      <h2 class="modal__title">
        <slot name="title" />
      </h2>
      <p>
        <slot name="message" />
      </p>
      <div class="button-group">
        <slot name="buttons" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/stylesheets/breakpoints.scss';
@import '@/assets/stylesheets/colors.scss';
@import '@/assets/stylesheets/z-order.scss';

.modal {
  background-color: $gray-4;
  margin: 0 1em;
  max-width: 30em;
  padding: 2em 3em;
  position: relative;

  &__container {
    align-items: center;
    background-color: $disabled;
    display: flex;
    justify-content: center;
    z-index: $z-index-modal;
  }

  &__close {
    color: $gray-400;
    cursor: pointer;
    font-size: 1.5rem;
    position: absolute;
    top: 1em;
    transition: color 0.2s;
    right: 1.5em;

    &:hover {
      color: var(--danger);
    }
  }
}

@media screen and (min-width: $medium) {
  .modal {
    margin: 0 2em;
  }
}
</style>
