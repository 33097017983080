import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { useAuthStore } from '@/store/authStore';

export default function requireUnauth(
  from: RouteLocationNormalized, to: RouteLocationNormalized, next: NavigationGuardNext,
): void {
  if (useAuthStore().isSignedIn) {
    (next({ name: 'Home' }));
  } else {
    next();
  }
}
