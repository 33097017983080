import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { useOrgStore } from '@/store/orgStore';
import { useAuthStore } from '@/store/authStore';

export default function isOwner(
  from: RouteLocationNormalized, to: RouteLocationNormalized, next: NavigationGuardNext,
): void {
  const authStore = useAuthStore();
  const orgStore = useOrgStore();

  if (orgStore.currentOrg?.ownerId.toString() === authStore.customerID) {
    next();
  } else {
    next({ name: 'Downloads', params: { ...to.params, subdomain: from.params.subdomain } });
  }
}
