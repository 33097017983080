<template>
  <span class="badge">
    <slot />
  </span>
</template>

<style lang="scss">
@import '@/assets/stylesheets/colors.scss';

.badge {
  background: linear-gradient(to right, $primary-light, $primary);
  border-radius: 4px;
  color: $high-em;
  font-size: 0.875rem;
  margin-top: 0;
  padding: 0.2em 0.5em;
}
</style>
