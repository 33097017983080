import { IErrorAggregate } from './errorAggregate';
import { validateEmail, validateRequired } from '.';

export interface IContactErrors extends IErrorAggregate {
  emailError: string;
  messageError: string;
}

export function validateContactData({ email, message }: { email: string, message: string }):
  IContactErrors {
  const errors = {
    emailError: validateEmail(email),
    messageError: validateRequired(message),
    hasErrors: false,
  };

  errors.hasErrors = !!(errors.emailError || errors.messageError);

  return errors;
}
