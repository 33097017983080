import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { useAuthStore } from '@/store/authStore';

export default function requireAuth(
  to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext,
): void {
  if (to.meta.authorize && !useAuthStore().isSignedIn) {
    next({ name: 'Sign In Form', query: { redirect: to.fullPath } });
  } else {
    next();
  }
}
