
import { defineComponent } from 'vue';
import Hero from '@/components/sections/Hero.vue';
import Introduction from '@/components/sections/Introduction.vue';
import Demo from '@/components/sections/Demo.vue';
import Features from '@/components/sections/features/Features.vue';
import Contact from '@/components/sections/Contact.vue';
import Pricing from '@/components/sections/pricing/Pricing.vue';

export default defineComponent({
  components: {
    Hero,
    Introduction,
    Demo,
    Features,
    Contact,
    Pricing,
  },
});
