<template>
  <section class="demo section" id="demo">
    <p class="demo__text">Check out the demo and see for yourself.</p>
    <external-anchor class="demo__button button--black"
        href="https://demo.steamcord.link"
        :show-icon="false">
      Show me
      <fa-icon :icon="['far', 'arrow-right']" />
    </external-anchor>
  </section>
</template>

<style lang="scss">
@import '@/assets/stylesheets/breakpoints.scss';
@import '@/assets/stylesheets/colors.scss';

.demo {
  background-color: $primary;
  padding-top: 6em;

  &__text {
    color: white;
    font-style: italic;
    margin: 0;
  }

  &__button {
    margin-top: 3em;
  }
}

@media screen and (min-width: $medium) {
  .demo {
    align-items: center;
    display: flex;
    gap: 1.5em;
    justify-content: center;

    &__button {
      margin: 0;
    }
  }
}

@media screen and (min-width: $large) {
  .demo {
    background: url('~@/assets/lines-2.png'), $primary;
    background-position: center left;
    background-size: cover;
  }
}
</style>
