import { defineStore } from 'pinia';
import { IMemberPermissions } from '../entities/memberPermissions';
import { IOrganization } from '../entities/organization';
import { getOrgBySubdomain, getPermissions } from '../services/steamcord/orgs';

export const useOrgStore = defineStore('orgs', {
  actions: {
    async setCurrentOrg(subdomain: string) {
      const org = await getOrgBySubdomain(subdomain);
      this.currentOrg = org;
    },
    async setPermissions() {
      if (!this.currentOrg) {
        return;
      }

      const permissions = await getPermissions(this.currentOrg.id);
      this.permissions = permissions;
    },
  },
  state() {
    return {
      currentOrg: undefined as IOrganization | undefined,
      orgs: [] as IOrganization[],
      permissions: undefined as IMemberPermissions | undefined,
    };
  },
});
