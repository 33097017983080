
import { Options, Vue } from 'vue-class-component';

function getUpdateInterval(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min)) + min;
}

@Options({
  props: {
    values: {
      type: Array,
      required: true,
    },
  },
})
export default class TypingCarousel extends Vue {
  values!: string[];

  finished = false;

  index = 0;

  value = '';

  get finalLength(): number {
    return this.values[this.index].length;
  }

  mounted(): void {
    setTimeout(this.increaseValue, 1000);
  }

  private decreaseValue() {
    if (this.finished || this.index === this.values.length - 1) {
      return;
    }

    if (this.value.length === 0) {
      this.updateIndex();
      setTimeout(this.increaseValue, 1000);
      return;
    }

    this.value = this.value.substring(0, this.value.length - 1);

    setTimeout(this.decreaseValue, getUpdateInterval(25, 50));
  }

  private increaseValue() {
    if (this.finished) {
      return;
    }

    if (this.value.length === this.finalLength) {
      setTimeout(this.decreaseValue, 1000);
      return;
    }

    this.value = this.values[this.index].substring(0, this.value.length + 1);

    setTimeout(this.increaseValue, getUpdateInterval(25, 125));
  }

  private updateIndex() {
    this.index += 1;
    if (this.index === this.values.length) {
      this.finished = true;
    }
  }
}
