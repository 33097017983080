
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['update:modelValue'],
  props: {
    disabled: Boolean,
    modelValue: Boolean,
  },
});
