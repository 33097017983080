<template>
  <section class="introduction section" id="intro">
    <div class="introduction__container">
      <p class="introduction__text">
        Reward players in-game for linking accounts, joining your community's Discord guild,
        Steam group, and more. <b class="primary">Reach more players</b> on
        <b class="primary">multiple platforms</b>. <b class="primary">Build a community</b>.
      </p>
    </div>
  </section>
</template>

<style lang="scss">
@import '@/assets/stylesheets/breakpoints.scss';
@import '@/assets/stylesheets/colors.scss';

.introduction {
  background-color: $gray-1;
  padding-top: 6em;
  text-align: center;

  &__container {
    margin: 0 auto;
    max-width: 50em;
  }

  &__text {
    color: $gray-700;
    line-height: 2;
    margin: 0;
  }

  // &__platforms {
  //   justify-content: center;
  //   display: flex;
  //   gap: 1em;
  //   font-size: 3rem;
  //   list-style: none;
  //   margin: 1em auto 0;
  //   padding: 0;
  // }

  // &__platform {
  //   margin-top: 0;
  //   color: $black;

  &__text {
    color: $high-em;
    line-height: 2;
  }
}

@media screen and (min-width: $medium) {
  .introduction {
    &__text {
    font-size: 1.125rem;
    }
  }
}
</style>
