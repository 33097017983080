<template>
  <footer class="steam-copyright">
    <p class="steam-copyright__text">
      Steamcord is in no way affiliated with Valve Corporation or Steam.
    </p>
    <p class="steam-copyright__text">
      &copy; {{ new Date().getFullYear() }} Valve Corporation. Steam and the Steam logo are
      trademarks and/or registered trademarks of Valve Corporation in the U.S. and/or other
      countries. All rights reserved.
    </p>
  </footer>
</template>

<style lang="scss">
@import '@/assets/stylesheets/colors.scss';

.steam-copyright {
  background-color: $gray-4;
  font-size: 0.875rem;
  margin-top: 0;
  padding: 2em 4em;
  text-align: center;

  &__text {
    color: $medium-em;
    margin: 0;
  }
}
</style>
