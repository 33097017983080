
import { defineComponent } from 'vue';
import Badge from '@/components/Badge.vue';

export default defineComponent({
  components: {
    Badge,
  },
  props: {
    icon: {
      type: Array,
      required: true,
    },
    pro: {
      type: Boolean,
      default: false,
    },
  },
});
