import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nav_bar = _resolveComponent("nav-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_app_footer = _resolveComponent("app-footer")!
  const _component_steam_copyright = _resolveComponent("steam-copyright")!
  const _component_spinner = _resolveComponent("spinner")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["container", { dark: true, 'screen-height': _ctx.screenHeight }])
  }, [
    (_ctx.showNavBar)
      ? (_openBlock(), _createBlock(_component_nav_bar, {
          key: 0,
          sticky: _ctx.stickyNav
        }, null, 8, ["sticky"]))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view),
    (_ctx.showFooter)
      ? (_openBlock(), _createBlock(_component_app_footer, {
          key: 1,
          large: _ctx.largeFooter
        }, null, 8, ["large"]))
      : _createCommentVNode("", true),
    (_ctx.showSteamCopyright)
      ? (_openBlock(), _createBlock(_component_steam_copyright, { key: 2 }))
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(_component_spinner, null, null, 512), [
      [_vShow, _ctx.loading]
    ])
  ], 2))
}