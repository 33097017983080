import axios from 'axios';
import { IUser } from '../../entities/user';

export interface ISignUpData {
  fullName: string;
  email: string;
  password: string;
}

export async function signUp(signUpData: ISignUpData): Promise<void> {
  await axios.post('/signup', signUpData);
}

export interface ISignInResponse {
  token: string;
  emailVerified: boolean;
}

export async function signIn(
  email: string, password: string,
): Promise<ISignInResponse | undefined> {
  try {
    const { data: response } = await axios.post<ISignInResponse>('/signin', {
      email,
      password,
    });

    return response;
  } catch (err) {
    if (axios.isAxiosError(err) && err.response && [400, 401].includes(err.response.status)) {
      return undefined;
    }

    throw err;
  }
}

export async function createPasswordReset(email: string): Promise<void> {
  await axios.put('/password-resets', { email });
}

export async function tryResetPassword(
  email: string, code: string, password: string,
): Promise<boolean> {
  try {
    await axios.delete(`/password-resets/${email}?code=${code}`, { data: { password } });
  } catch (err) {
    if (
      axios.isAxiosError(err) && err.response?.status && ([400, 404].includes(err.response?.status))
    ) {
      return false;
    }

    throw err;
  }

  return true;
}

export async function tryVerifyEmail(email: string, code: string): Promise<boolean> {
  try {
    await axios.delete(`/verification-emails/${email}?code=${code}`);
  } catch (err) {
    if (
      axios.isAxiosError(err) && err.response?.status && [400, 404].includes(err.response?.status)
    ) {
      return false;
    }

    throw err;
  }

  return true;
}

export async function resendVerificationEmail(email: string): Promise<void> {
  await axios.post(`/verification-emails/${email}`);
}

export async function isEmailVerified(): Promise<boolean> {
  const { data: user } = await axios.get<IUser>('/@me');
  return user.isVerified;
}
