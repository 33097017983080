import { defineStore } from 'pinia';
import { isEmailVerified } from '../services/steamcord/auth';
import setAuthorizationHeader from '../util/axios';

const CLAIMS = {
  IDENTIFIER: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier',
  EMAIL: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
};

function getToken() {
  const token = localStorage.getItem('token');
  if (token) {
    setAuthorizationHeader(token);
  }

  return token ?? undefined;
}

export const useAuthStore = defineStore('auth', {
  actions: {
    async setEmailVerified() {
      if (localStorage.getItem('emailVerified') === 'true') {
        this.emailVerified = true;
        return;
      }

      this.emailVerified = await isEmailVerified();
      localStorage.setItem('emailVerified', this.emailVerified.toString());
    },
    async signOut() {
      this.token = undefined;
      localStorage.removeItem('token');
    },
  },
  // TODO: return these separately with the token
  getters: {
    customerID(): string | undefined {
      if (!this.token) {
        return undefined;
      }

      return JSON.parse(atob(this.token?.split('.')[1]))[CLAIMS.IDENTIFIER];
    },
    email(): string | undefined {
      if (!this.token) {
        return undefined;
      }

      return JSON.parse(atob(this.token?.split('.')[1]))[CLAIMS.EMAIL];
    },
    isSignedIn(): boolean {
      if (!this.token) {
        return false;
      }

      return JSON.parse(atob(this.token?.split('.')[1])).exp * 1000 > Date.now();
    },
  },
  state() {
    const token = getToken();
    return {
      emailVerified: false,
      token,
    };
  },
});
