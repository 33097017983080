<template>
  <footer :class="large ? 'footer--large' : 'footer'">
    <span>Copyright &copy; {{ new Date().getFullYear() }} Steamcord LLC</span>
    <span class="footer__dashes"> -- </span>
    <div class="footer__links">
      <router-link class="footer__link anchor--white" to="/terms">Terms</router-link>

      <span class="footer__comma">, </span>

      <router-link class="footer__link anchor--white" to="/privacy">Privacy</router-link>
    </div>

    <div class="footer__social-links">
      <external-anchor class="footer__social-link"
          href="https://github.com/Steamcord"
          :show-icon="false">
        <fa-icon :icon="['fab', 'github']" />
      </external-anchor>

      <external-anchor class="footer__social-link"
          href="https://discord.gg/AT5WVY4fJR"
          :show-icon="false">
        <fa-icon :icon="['fab', 'discord']" />
      </external-anchor>
    </div>
  </footer>
</template>

<style lang="scss">
@import '@/assets/stylesheets/breakpoints.scss';
@import '@/assets/stylesheets/colors.scss';

.footer {
  background-color: $gray-2;
  color: $high-em;
  margin-top: auto;
  padding: 3em;
  text-align: center;

  &--large {
    @extend .footer;
    padding: 6em;
  }

  &__dashes,
  &__comma {
    display: none;
  }

  &__links {
    margin-top: 1em;
  }

  &__link:first-child {
    margin-left: 0;
  }

  &__link {
    margin-left: 1em;
  }

  &__social-links {
    font-size: 2rem;
    margin-top: 0.5em;
  }

  &__social-link {
    color: $high-em;
    transition: color 0.2s;

    &:hover {
      color: $gray-300;
    }
  }

  &__social-link + &__social-link {
    margin-left: 0.5em;
  }
}

@media screen and (min-width: $large) {
  .footer {
    &__dashes,
    &__comma {
      display: initial;
    }

    &__links {
      display: inline;
    }

    &__link {
      margin-left: 0;
    }
  }
}
</style>
