
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
    features: {
      type: Array,
      required: true,
    },
    disabled: Boolean,
    gradientBorder: Boolean,
  },
});
