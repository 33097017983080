import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["placeholder", "value"]
const _hoisted_2 = { class: "input-text-group__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _mergeProps({ class: "input-text-group" }, _ctx.$attrs), [
      _createElementVNode("input", {
        class: "input-text-group__input",
        placeholder: _ctx.placeholder,
        value: _ctx.modelValue,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
      }, null, 40, _hoisted_1),
      _createElementVNode("span", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ], 16),
    _withDirectives(_createElementVNode("p", { class: "form__error" }, _toDisplayString(_ctx.error), 513), [
      [_vShow, _ctx.error]
    ])
  ], 64))
}