import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("textarea", _mergeProps(_ctx.$attrs, {
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
    }), null, 16, _hoisted_1),
    _withDirectives(_createElementVNode("p", { class: "form__error" }, _toDisplayString(_ctx.error), 513), [
      [_vShow, _ctx.error]
    ])
  ], 64))
}