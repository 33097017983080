import { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import requireOwner from './guards/requireOwner';
import requirePermissions from './guards/requirePermissions';
import requireVerification from './guards/requireVerification';
import { useMainStore } from '@/store/mainStore';
import { useOrgStore } from '@/store/orgStore';

async function subdomainMiddleware(
  to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext,
): Promise<void> {
  const mainStore = useMainStore();
  mainStore.loading = true;

  const { subdomain } = to.params;

  const store = useOrgStore();

  await store.setCurrentOrg(subdomain.toString());

  if (!store.currentOrg) {
    next({ name: 'Not Found' });
    return;
  }

  await store.setPermissions();

  if (to.name === 'Redirect') {
    next({ name: 'Players', params: { subdomain }, replace: true });
  } else {
    next();
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/dashboard/new',
    name: 'New Organization',
    component: () => import('@/views/dashboard/NewOrganization.vue'),
    meta: {
      authorize: true,
      hideNavBar: true,
      hideFooter: true,
      screenHeight: true,
    },
    beforeEnter: requireVerification,
  },
  {
    path: '/dashboard/integrations/new',
    name: 'New Integration OAuth Redirect',
    component: () => import('@/views/dashboard/NewIntegrationOAuthRedirect.vue'),
  },
  {
    path: '/dashboard/settings',
    name: 'Settings OAuth Redirect',
    component: () => import('@/views/dashboard/SettingsOAuthRedirect.vue'),
  },
  {
    path: '/dashboard/',
    name: 'Dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      authorize: true,
      hideFooter: true,
      hideNavBar: true,
      screenHeight: true,
    },
    beforeEnter: requireVerification,
    children: [
      {
        path: '',
        name: 'Organizations',
        component: () => import('@/views/dashboard/Organizations.vue'),
        meta: { hideSideNav: true },
      },
      {
        path: ':subdomain/',
        name: 'Redirect',
        component: () => import('@/views/dashboard/SubdomainWrapper.vue'),
        meta: { title: 'Dashboard' },
        beforeEnter: subdomainMiddleware,
        children: [
          {
            path: 'settings',
            name: 'Settings',
            beforeEnter: requirePermissions((p) => p.manageSettings),
            component: () => import('@/views/dashboard/Settings.vue'),
          },
          {
            path: 'players',
            name: 'Players',
            beforeEnter: requirePermissions((p) => p.readPlayers),
            component: () => import('@/views/dashboard/players/Players.vue'),
          },
          {
            path: 'players/:id',
            name: 'Player Details',
            beforeEnter: requirePermissions((p) => p.readPlayers),
            component: () => import('@/views/dashboard/players/Player.vue'),
          },
          {
            path: 'tokens',
            name: 'API Tokens',
            beforeEnter: requirePermissions((p) => p.manageApiTokens),
            component: () => import('@/views/dashboard/ApiTokens.vue'),
          },
          {
            path: 'integrations',
            name: 'Integrations',
            beforeEnter: requirePermissions((p) => p.administrator),
            component: () => import('@/views/dashboard/integrations/Integrations.vue'),
          },
          {
            path: 'integrations/new',
            name: 'New Integration',
            beforeEnter: requirePermissions((p) => p.administrator),
            component: () => import('@/views/dashboard/integrations/NewIntegration.vue'),
          },
          {
            path: 'integrations/:id',
            name: 'Integration Details',
            beforeEnter: requirePermissions((p) => p.administrator),
            component: () => import('@/views/dashboard/integrations/Integration.vue'),
          },
          {
            path: 'integrations/:id/new-action',
            name: 'New Action',
            beforeEnter: requirePermissions((p) => p.administrator),
            component: () => import('@/views/dashboard/integrations/NewAction.vue'),
          },
          {
            path: 'integrations/:integrationID/actions/:actionID',
            name: 'Action Details',
            beforeEnter: requirePermissions((p) => p.administrator),
            component: () => import('@/views/dashboard/integrations/Action.vue'),
            meta: {
              hideTitle: true,
            },
          },
          // {
          //   path: 'integrations/:serverID/roles',
          //   name: 'Role Linking',
          //   beforeEnter: requirePermissions((p) => p.administrator),
          //   component: () => import('@/views/dashboard/integrations/Roles.vue'),
          // },
          {
            path: 'members',
            name: 'Members',
            beforeEnter: requirePermissions((p) => p.administrator),
            component: () => import('@/views/dashboard/members/Members.vue'),
          },
          {
            path: 'members/:id',
            name: 'Member Details',
            beforeEnter: requirePermissions((p) => p.administrator),
            component: () => import('@/views/dashboard/members/Member.vue'),
            meta: {
              hideTitle: true,
            },
          },
          {
            path: 'downloads',
            name: 'Downloads',
            component: () => import('@/views/dashboard/Downloads.vue'),
          },
          {
            path: 'billing',
            name: 'Billing',
            beforeEnter: requireOwner,
            component: () => import('@/views/dashboard/Billing.vue'),
          },
        ],
      },

    ],
  },
];

export default routes;
