
import { mapState } from 'pinia';
import { defineComponent } from 'vue';
import { useMainStore } from './store/mainStore';
import NavBar from './components/NavBar.vue';
import AppFooter from './components/sections/AppFooter.vue';
import SteamCopyright from './components/sections/SteamCopyright.vue';
import Spinner from './components/Spinner.vue';

export default defineComponent({
  components: {
    NavBar,
    AppFooter,
    SteamCopyright,
    Spinner,
  },
  computed: {
    ...mapState(useMainStore, ['loading']),
    largeFooter(): boolean {
      return !!this.$route.meta?.largeFooter;
    },
    screenHeight(): boolean {
      return !!this.$route.meta?.screenHeight;
    },
    showNavBar(): boolean {
      return !this.$route.meta?.hideNavBar;
    },
    showFooter(): boolean {
      return !this.$route.meta?.hideFooter;
    },
    showSteamCopyright(): boolean {
      return !!this.$route.meta?.showSteamCopyright;
    },
    stickyNav(): boolean {
      return !!this.$route.meta?.stickyNav;
    },
  },
});
