import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { useAuthStore } from '@/store/authStore';

export default function emailVerificationMiddleware(
  to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext,
): void {
  const store = useAuthStore();
  if (!store.emailVerified) {
    next({ name: 'Sign In Verify Email', params: { email: store.email } });
  } else {
    next();
  }
}
