import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0923fe29"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "external-anchor",
  target: "_blank",
  rel: "noopener noreferrer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("a", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    (_ctx.showIcon)
      ? (_openBlock(), _createBlock(_component_fa_icon, {
          key: 0,
          class: "external-anchor__icon",
          icon: ['far', 'external-link']
        }))
      : _createCommentVNode("", true)
  ]))
}