
import { mapState } from 'pinia';
import { defineComponent } from 'vue';
import { useAuthStore } from '@/store/authStore';
import ExternalAnchor from './ExternalAnchor.vue';
import Logo from './Logo.vue';

export default defineComponent({
  components: {
    ExternalAnchor,
    Logo,
  },
  computed: mapState(useAuthStore, ['isSignedIn']),
  data() {
    return {
      navOpen: false,
    };
  },
  methods: {
    async goHome() {
      await this.$router.push({ name: 'Home' });
      this.closeNav();
    },
    openNav() {
      this.navOpen = true;
    },
    closeNav() {
      setTimeout(() => {
        this.navOpen = false;
      }, 100);
    },
  },
  props: {
    sticky: Boolean,
  },
});
