
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    showArrow: {
      type: Boolean,
      default: true,
    },
    buttonType: {
      type: String,
      default: 'button--primary',
    },
    icon: {
      type: String,
      default: 'arrow-right',
    },
  },
});
