import NProgress from 'nprogress';
import { nextTick } from 'vue';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import dashboardRoutes from './dashboard';
import requireAuth from './guards/requireAuth';
import requireUnauth from './guards/requireUnauth';
import { useAuthStore } from '@/store/authStore';
import Home from '@/views/Home.vue';

const routes: Array<RouteRecordRaw> = [
  ...dashboardRoutes,
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      largeFooter: true,
      title: 'Grow Your Gaming Community',
      showSteamCopyright: true,
    },
  },
  {
    path: '/signin/',
    name: 'Sign In',
    component: () => import('@/views/auth/SignIn.vue'),
    meta: {
      hideFooter: true,
      title: 'Sign In',
    },
    children: [
      {
        path: '',
        name: 'Sign In Form',
        beforeEnter: requireUnauth,
        component: () => import('@/views/auth/SignInForm.vue'),
      },
      {
        path: 'verify/:email',
        name: 'Sign In Verify Email',
        beforeEnter(to, from, next) {
          const store = useAuthStore();
          if (store.emailVerified) {
            next('/dashboard');
          } else {
            const paramsEmail = to.params.email.toString().toLowerCase();
            const email = store.email?.toString().toLowerCase();

            if (paramsEmail !== email) {
              next({ name: 'Sign In Verify Email', params: { email } });
            }

            next();
          }
        },
        component: () => import('@/views/auth/VerifyEmail.vue'),
        meta: {
          authorize: true,
          redirect: 'Organizations',
        },
      },
    ],
  },
  {
    path: '/signup/',
    name: 'Sign Up',
    beforeEnter: requireUnauth,
    component: () => import('@/views/auth/SignUp.vue'),
    meta: {
      hideFooter: true,
      title: 'Sign Up',
    },
    children: [
      {
        path: '',
        name: 'Sign Up Create Account',
        component: () => import('@/views/auth/CreateAccount.vue'),
      },
      {
        path: 'verify/:email',
        name: 'Sign Up Verify Email',
        component: () => import('@/views/auth/VerifyEmail.vue'),
      },
    ],
  },
  {
    path: '/reset-password/',
    name: 'Reset Password',
    beforeEnter: requireUnauth,
    component: () => import('@/views/auth/ResetPassword.vue'),
    meta: {
      hideFooter: true,
      title: 'Reset Password',
    },
    children: [
      {
        path: '',
        name: 'Reset Password Form',
        component: () => import('@/views/auth/EmailForm.vue'),
      },
      {
        path: 'verify/:email',
        name: 'Reset Password Verify Email',
        component: () => import('@/views/auth/ResetPasswordVerify.vue'),
      },
    ],
  },
  {
    path: '/signout',
    name: 'Sign Out',
    redirect() {
      useAuthStore().signOut();
      return { name: 'Home' };
    },
  },
  {
    path: '/privacy',
    name: 'Privacy Policy',
    component: () => import('@/views/legal/PrivacyPolicy.vue'),
    meta: { largeFooter: true, scrollToTop: true },
  },
  {
    path: '/terms',
    name: 'Terms of Service',
    component: () => import('@/views/legal/TermsOfService.vue'),
    meta: { largeFooter: true, scrollToTop: true },
  },
  {
    path: '/500',
    name: 'Internal Server Error',
    component: () => import('@/views/InternalError.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    component: () => import('@/views/NotFound.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.meta?.scrollToTop) {
      return { left: 0, top: 0 };
    }

    if (savedPosition) {
      return savedPosition;
    }

    return to.hash ? { el: to.hash } : { left: 0, top: 0 };
  },
});

router.beforeEach(requireAuth);

router.beforeEach((to, from, next) => {
  nextTick(() => {
    window.document.title = `${to.meta.title || to.name} | Steamcord`;
  });

  next();
});

router.beforeEach(() => {
  NProgress.start();
});

router.afterEach(() => {
  NProgress.done();
});

router.onError(() => {
  NProgress.done();
});

export default router;

function matchedSome(routeName: string) {
  return router.currentRoute.value.matched.some(({ name }) => name === routeName);
}

export function currentRouteIsHome(): boolean {
  return matchedSome('Home');
}

export function currentRouteIsDashboard(): boolean {
  return matchedSome('Dashboard');
}
